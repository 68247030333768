
<script setup>
    import { computed } from "vue";

    import MCard from "mobile.vue.components.Card.vue";

    const props = defineProps({
        color: { type: String, required: false, default: "primary" },
        icon : { type: String, required: false                     },
        title: { type: String, required: true                      },
        text : { type: String, required: false                     },
        type : { type: String, required: false, default: "primary" },
        modelValue: {},
    });
    const emit = defineEmits(["update:modelValue"]);

    const alertType = computed(() => props.type || props.color);

    const icons = {
        "danger": "fas fa-exclamation-circle",
        "warning": "fas fa-exclamation-triangle",
        "success": "fas fa-check",
    };

    const icon = computed(() => icons[alertType.value] || props.icon);
    const style = computed(() => {
        return {
            "color": `var(--bs-${alertType.value}-text-emphasis)`,
            "background": `var(--bs-${alertType.value}-bg-subtle)`,
            "border": `1px solid var(--bs-${alertType.value}-border-subtle)`,
        };
    });
</script>

<template>
    <MCard class="p-3 d-flex gap-3" :style="style" v-if="modelValue !== false">
        <template v-if="icon">
            <div class="d-flex align-items-center" style="height: 1.5em;">
                <i :class="icon" style="font-size: 1.5em;" />
            </div>
        </template>
        <div>
            <div class="d-flex align-items-center" style="height: 1.5em; font-size: 1.125em; font-weight: 500;">
                {{ props.title }}
            </div>
            <div class="mt-2">
                {{ props.text }}
            </div>
        </div>
        <template v-if="modelValue != null">
            <button style="position: absolute; top: 0; right: 0; width: 3rem; height: 3rem; background: none; border: none;" @click="emit('update:modelValue', false)">
                <i class="bi bi-x font-4 text-muted" />
            </button>
        </template>
    </MCard>
</template>
